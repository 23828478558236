import Vue from 'vue';
import Router from 'vue-router';
// import store from '@/store'
// import Cookies from 'js-cookie'
Vue.use(Router);

/* Layout */
import Layout from '@/layout';
export var constantRoutes = [{
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/register',
  component: import('@/views/register/index'),
  // redirect: '/register/index',
  name: 'register',
  meta: {
    title: '注册',
    icon: 'excel'
  },
  hidden: true,
  children: [{
    path: 'index',
    name: 'registerIndex',
    component: function component() {
      return import('@/views/register/step1');
    },
    meta: {
      title: '注册'
    }
  }]
}, {
  path: '/404',
  component: function component() {
    return import('@/views/404');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    meta: {
      title: '首页',
      icon: 'dashboard'
    }
  }]
}];
var createRouter = function createRouter() {
  return new Router({
    mode: 'hash',
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;